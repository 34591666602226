import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import type {
  FaqComponent_Plain,
  Home_Plain,
  OurWork_Plain,
  Reviews_Plain,
  TechStack_Plain,
  Testimonials_Plain,
  CountryComponent_Plain,
} from '@webamboos/admin'
import type { IndustryCard_Plain } from '@webamboos/admin/src/api/industry-card/content-types/industry-card/industry-card'
import type { Industries, Industries_Plain } from '@webamboos/admin/src/components/sections/interfaces/Industries'
import industriesVue from '~/components/homepage/industries/industries.vue'

export function useHome() {
  const { findOne } = useStrapi()
  const { localeProperties } = useI18n()

  const locale = computed(() => localeProperties.value.code as StrapiLocale)

  /** This is a shared state between server and client.
   * Should be used in components that need data from the homepage instead of passing around props. */
  const home = useState<Home_Plain>(`home_${locale.value}`)
  const clients = useState<Testimonials_Plain>(`home_clients_${locale.value}`)
  const ourWork = useState<OurWork_Plain>(`home_our_work_${locale.value}`)
  const reviews = useState<Reviews_Plain | undefined>(`home_reviews_${locale.value}`)
  const tech = useState<TechStack_Plain>(`home_tech_${locale.value}`)
  const faq = useState<FaqComponent_Plain>(`home_faq_${locale.value}`)
  const map = useState<CountryComponent_Plain | undefined>(`home_map_${locale.value}`)
  const industries = useState<Industries_Plain | undefined>(`home_industries_${locale.value}`)

  const fetch = async () => {
    const result = await findOne('home', {
      locale: locale.value,
      populate: {
        hero: {
          populate: {
            action: true,
          },
        },
        team: {
          populate: {
            stats: true,
            image: true,
            action: true,
          },
        },
        reviews: {
          populate: {
            testimonials: {
              populate: ['image', 'partner'],
            },
          },
        },
        page_meta: {
          populate: {
            image: true,
          },
        },
        clients: {
          populate: {
            partners: {
              populate: ['logo'],
            },
            testimonials: {
              populate: ['image', 'partner'],
            },
          },
        },
        projects: {
          populate: {
            action: {
              populate: '*',
            },
            projects: {
              populate: ['industries', 'image'],
            },
          },
        },
        tech: {
          populate: {
            technologies: {
              populate: ['image'],
            },
          },
        },
        faq: {
          populate: '*',
        },
        map: {
          populate: '*',
        },
        industries: {
          populate: {
            industry_cards: {
              populate: ['icon'],
            },
          },
        },
      },
    })

    // we are using a plugin that transforms the response to a flatter structure
    const attrs = result.data as unknown as Home_Plain

    home.value = attrs
    reviews.value = attrs.reviews
    ourWork.value = attrs.projects
    tech.value = attrs.tech
    clients.value = attrs.clients
    faq.value = attrs.faq
    map.value = attrs.map
    industries.value = attrs.industries

    return attrs
  }

  return {
    fetch,

    home,
    clients,
    ourWork,
    reviews,
    tech,
    faq,
    map,
    industries,
  }
}
